import React, { useContext, useState, useEffect, createContext, useLayoutEffect, useRef } from 'react';
import axios from "axios";
import Input from './Input';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import { AppContext } from '../Home';
import { animateScroll } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CryptoJS from 'crypto-js';
import Resizer from "react-image-file-resizer";
import { faList, faCircleCheck, faUserPlus, faAddressCard, faTintSlash, faComments, faMobileAlt, faCreditCard, 
    faCircleInfo, faWallet, faListNumeric, faFileClipboard, faFileCirclePlus, faFileCircleQuestion, faEnvelope, 
    faHandHoldingHand,
    faUserSecret, 
    faUserLock,  
    faCloudUpload,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from 'react-loader-spinner';

export const StepsContext = createContext(null);

const Steps = () => {

    const { setInput, phone, value, setValue, setAuth, setOtp, 
        input, setApplication, chat, setChat, setName, setFollowUp, setPassword,
        config, setLoggedIn, setChartLabels, setChartData, setAccountData, dev, setForm, setUser } = useContext(AppContext);
    const [articleModal, setArticleModal] = useState(false);
    const [articles, setArticles] = useState([]);
    const [article, setArticle] = useState({});
    const [accounts, setAccounts] = useState([]);
    const [profile, setProfile] = useState({});
    const [action, setAction] = useState("");
    const [notices, setNotices] = useState([]);
    const [scroll, setScroll] = useState(true);
    const [balance, setBalance] = useState(0);    
    const [supplier, setSupplier] = useState(false);
    const [customer, setCustomer] = useState("");
    const [amount, setAmount] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [resetSupplierModal, setResetSupplierModal] = useState(false);
    const [registerSupplierModal, setRegisterSupplierModal] = useState(false);
    const [transModal, setTransModal] = useState(false);
    const [inputValue, setInputValue] = useState(""); 
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [values, setValues] = useState([]);
    const [file, setFile] = useState(); 
    const [preview, setPreview] = useState(false); 
    const [registerFeedback, setRegisterFeedback] = useState("");  
    const [resetFeedback, setResetFeedback] = useState("");
    const [loginFeedback, setLoginFeedback] = useState(""); 
    const [registerOTP, setRegisterOTP] = useState(false);  
    const [resetOTP, setResetOTP] = useState(false);
    const inputFile = useRef(null); 
    const columns = [
        {
            name: 'Date',
            selector: row => row.Date,
        },
        {
            name: 'Opening Balance',
            selector: row => row.OpeningBalance,
        },
        {
            name: 'Type',
            selector: row => row.Type,
        },
        {
            name: 'Description',
            selector: row => row.Description,
        },
        {
            name: 'Amount',
            selector: row => row.Amount,
        },        
        {
            name: 'Balance',
            selector: row => row.Balance,
        }
    ];            

    const date = new Date;
    const hours = date.getHours();
    const time = (hours < 12) ? "Good Morning" :
        ((hours <= 18 && hours >= 12) ? "Good afternoon" : "Good evening");

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let sess = btoa('sess-' + year + "-" + month + "-" + date.getDate());

    const Greeting =
    {
        entity: "bot",
        prompt: "<p><strong>" + time + " visitor, I am SiluluBot.</strong> <br>Please select a category below to get started:</p>",
        options: [
            { process: "NewCustomer", text: "New Customer", icon: faUserPlus, color: "btn-info"},
            { process: "ExistingCustomer", text: "Existing Customer", icon: faAddressCard, color: "btn-info"},
            { process: "SupplyDisruption", text: "Supply Disruption", icon: faTintSlash, color: "btn-info"},
            { process: "SupplierOptions", text: "I am a Supplier", icon: faHandHoldingHand, color: "btn-info"}]
    };

    const [messages, setMessage] = useState([Greeting]);


    useEffect(() => {
        if (scroll) {
            const timeout = setTimeout(() => {
                animateScroll.scrollToBottom({
                    containerId: "messages"
                });
            }, 200);
            return () => clearTimeout(timeout);
        }
    }, [messages]);


    useLayoutEffect(() => {
        Action(action);
    }, [action]);


    const Action = () => { 

        switch (action) {

            case "Phone": Step["Authenticate"](phone);
                break;

            case "CheckOtp": Step["CheckOtp"]();
                break;

            case "CheckApplication": Step["CheckApplication"]();
                break;

            case "CheckSupplier": Step["CheckSupplier"]();
                break;

           case "FollowUp": Step["FollowUp"]();
                break;

            case "SetAmount": Step["SetAmount"]();
                break;

            case "GetName": Step["GetName"]();
                break;

            case "Dev": Step["Dev"]();
                break;

            default: setValue("");
        }
    }


    const Step = {

        NewCustomer: () => {
            setInput(false); setAuth(false);
            setMessage([...messages, {
                entity: "bot",
                prompt: "<h6>New Customer Options:</h6>",
                options: [
                    { process: "OpenAccount", text: "How to open an account", icon: faCircleInfo, color: "btn-info"},
                    { process: "ApplicationStatus", text: "Check new application status", icon: faCircleCheck, color: "btn-info"},
                    { process: "ChatOptions", text: "Chat with a person", icon: faComments, color: "btn-success"}]
            }])
        },

        SupplyDisruption: () => {
            setInput(false); setAuth(false);
            setMessage([...messages, {
                entity: "bot",
                prompt: "<h6>Supply Disruption Options:</h6>",
                options: [
                    { process: "SupplyInterruption", text: "View Active Issues", icon: faList, color: "btn-info"},
                    { process: "IssueFollowUp", text: "Follow Up Reported Issue", icon: faFileCircleQuestion, color: "btn-info"},
                    { process: "ReportIssue", text: "Report New Issue", icon: faFileCirclePlus, color: "btn-success"},
                    { process: "ChatOptions", text: "Chat with a person", icon: faComments, color: "btn-success"}]
            }])
        },

        SupplierOptions: () => {
            setInput(false); setAuth(false);
            setMessage([...messages, {
                entity: "bot",
                prompt: "<h6>Supplier Options:</h6>",
                options: [
                    { process: "SupplierLogin", text: "Login", icon: faUserLock, color: "btn-info"},
                    { process: "SupplierPassword", text: "Reset password", icon: faUserSecret, color: "btn-info"},
                    { process: "SupplierRegister", text: "Register", icon: faCloudUpload, color: "btn-info"}]
            }])
        },

        SupplierLogin: () => {
            setLoginModal(true); 
            setMessage([...messages,
            {
                entity: "bot",
                prompt: "",
                options: []
            }])
        },

        SupplierPassword: () => {
            setResetSupplierModal(true); 
            setMessage([...messages,
            {
                entity: "bot",
                prompt: "",
                options: []
            }])
        },

        SupplierRegister: () => {
            setRegisterSupplierModal(true); 
        },

        ExistingCustomer: () => {
            setInput(true); setAuth(true); 
            if(window.location.host.includes("localhost"))
            {
                setValue("0832691437");
            }
            setMessage([...messages,
            {
                entity: "bot",
                prompt: "Please enter your cellphone number below:",
                options: []
            }])
        },

        ApplicationStatus: () => {
            setAction(""); setInput(true); setApplication(true); setMessage([...messages,
            {
                entity: "bot",
                prompt: "Please enter your application number below:",
                options: []
            }]);
        },

        IssueFollowUp: () => {
            setAction(""); setInput(true); setFollowUp(true); setMessage([...messages,
            {
                entity: "bot",
                prompt: "Please enter your issue log number below:",
                options: []
            }]);
        },
 
        ChatOptions: () => {
            setInput(false); setMessage([...messages,
            {
                entity: "bot",
                prompt: "<h6>Please select your preffered chat channel</h6>",
                options: [
                    { process: "WebChat", text: "Chat Here", icon: faComments, color: "btn-info"},
                    { process: "Whatsapp", text: "Whatsapp", icon: faMobileAlt, color: "btn-info"}]
            }])
        },

        AccountOptions: () => {
            setInput(false);
            setMessage([...messages,
            {
                entity: "bot",
                prompt: "<h6>Report Supply Interruption</h6>",
                options: [
                    { process: "OpenAccount", text: "How to open an account", icon: faList, color: "btn-info"},
                    { process: "ApplicationStatus", text: "Check new application status", icon: faCircleCheck, color: "btn-info"}]
            }])
        },

        OTPSent: () => {
            setOtp(true); setAuth(false); setInput(true); setMessage([...messages,
            {
                entity: "bot",
                prompt: "An <strong>OTP Code</strong> has been sent to <strong>" + phone + "</strong>. Please enter it below.",
                options: []
            }])
        },

        IncorrectOtp: () => {
            setMessage([...messages,
            {
                entity: "bot",
                prompt: "<div class='alert alert-danger'>The OTP code entered is <strong>incorrect</strong>. Please try again.</div>",
                options: []
            }]);
        },

        GetName: async () => {
            if (value != "") {
                window.sessionStorage.setItem("name", value);
                setAction("");
                setInput(false);
                setName(false);
                setUser(value);

                setMessage([...messages,
                {
                    entity: "bot",
                    prompt: "...",
                    options: []
                }]);

                let icon = document.getElementById('siluluQuickChat');
                icon.style.display = 'block';
                setChat(!chat);
            }
            else {
                Step.WebChat();
            }
        },

        CheckApplication: async () => {
            const formData = new FormData();
            formData.append('application_number', value);
            formData.append('getApplicationStatus', !0);
            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined &&
                        response.data.status === 1 &&
                        response.data.application_status.STATUS !== undefined) {

                        setAction("");
                        setInput(false);
                        setApplication(false);

                        setMessage([...messages,
                        {
                            entity: "bot",
                            prompt: "<h6>Application Number: " + value + "</h6><hr>Status: <strong>" + response.data.application_status.STATUS + "</strong>",
                            options: []
                        }]);
                    }
                    else {
                        setMessage([...messages,
                        {
                            entity: "bot",
                            prompt: "<div class='alert alert-warning'>Application number <strong>" + value + "</strong> was not found. Please try again.</div>",
                            options: []
                        }]);

                        setAction("");
                        setInput(true);
                        setApplication(true);
                    }
                })
            }
            catch (error) { }
        },

        CheckSupplier: async () => {             
            let email = value;
            const formData = new FormData();      
            const data = {key: value}     
            var encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), sess, {format: CryptoJSAesJson}).toString();
            formData.append("payload", encrypted);     
            try {
                const response = await axios.post(window.base_url + "supplier/id/", formData).then(async function (response) {
                if (response.data !== undefined &&
                    response.data.status === 1) {
                       if(response.data.token !== undefined)
                       {
                            setToken(response.data.token);
                            setInput(true); 
                            setPassword(true);  
                            setValue(""); 
                                                                                
                            setMessage([...messages,
                            {
                                entity: "bot",
                                prompt: "<div class='alert alert-success'>" + email + "</div><br>Please enter password:",
                                options: []
                            }]); 
                       }                                  
                    } 
                    else
                    {       
                        setSupplier();
                        setInput(true);
                        setMessage([...messages,
                        {
                            entity: "bot",
                            prompt: "<div class='alert alert-warning'>"+email+" provided does not match any login credentials.</div>Please try again.</p>",
                            options: []
                        }]);                                     
                    }
                })
            }
            catch (error) { }
        },
 

        FollowUp: async () => {
            const formData = new FormData();
            formData.append('issue_number', value);
            formData.append('getIssueStatus', !0);
            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.issue_status.STATUS !== undefined) {

                        setAction("");
                        setInput(false);
                        setFollowUp(false);

                        let status = response.data.issue_status.STATUS === 0 ? "Work in progress" : 
                        "Completed at " + response.data.issue_status.COMPLETED_STAMP_DATETIME;

                        setMessage([...messages,
                        {
                            entity: "bot",
                            prompt: "<h6>Issue Number: " + value + "</h6><hr>Status: <strong>" + status + "</strong>",
                            options: []
                        }]);
                    }
                    else {
                        setMessage([...messages,
                        {
                            entity: "bot",
                            prompt: "<div class='alert alert-warning'>The log number <strong>" + value + "</strong> was not found. Please try again.</div>",
                            options: []
                        }]);

                        setAction("");
                        setInput(true);
                        setApplication(true);
                    }
                })
            }
            catch (error) { }
        },

        AccountStatement: async () => {
            setInput(false);
 
            let email = profile.E_MAIL.includes(",") ? profile.E_MAIL.split(",") : profile.E_MAIL;
            if (Array.isArray(email)) {
                const emailList = [];

                email.forEach(e => {
                    emailList.push({ process: "SelectEmail", text: e, icon: "", color: "btn-info"});
                });

                setMessage([...messages, {
                    entity: "bot",
                    prompt: "There are <strong>" + email.length + "</strong> email addresses linked to your account. Please select one.",
                    options: emailList
                }]);
            }
            else if (email != "") {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-success'>Your statement will be emailed to: " + email + "</div>",
                    options: [
                        { process: "SendStatement", text: "Send Statement", icon: "", color: "btn-info"},
                        { process: "UpdateDetails", text: "Update Account Details", icon: "", color: "btn-info"}]
                }]);
            }
            else {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-danger'>No valid email address found. Please update your account details</div>",
                    options: [
                        { process: "ChatOptions", text: "Talk to Customer Support", icon: "", color: "btn-info"},
                        { process: "UpdateDetails", text: "Update Account Details", icon: "", color: "btn-info"}]
                }]);
            }
        },

        UpdateDetails: () => {
            setUpdateModal(true);
        },

        ReportIssue: () => {
            setReportModal(true);
        },

        RegisterSupplier: () => {
            setRegisterSupplierModal(true);
        },

        SendStatement: () => {
            Step.SelectEmail(profile.E_MAIL);
        },

        AccountStatus: async () => {
            setInput(false);
            const formData = new FormData();
            formData.append('account_number', profile.CUSTKEY);
            formData.append('getAccountStatus', !0);
            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {
                        if (response.data.account_status.CON_STATUS === 1) {
                            setMessage([...messages, {
                                entity: "bot",
                                prompt: "<h6>Account Number: " + profile.CUSTKEY + "</h6><hr>Status: <strong>Connected</strong>",
                                options: []
                            }]);
                        }

                        if (response.data.account_status.CON_STATUS === 2) {
                            setMessage([...messages, {
                                entity: "bot",
                                prompt: "<h6>Account Number: " + profile.CUSTKEY + "</h6><hr>Status: <strong>Disconnected</strong>",
                                options: []
                            }]);
                        }
                    }
                })
            }
            catch (error) { }
        },

        OpenAccount: async () => {
            setMessage([...messages,
            {
                entity: "bot",
                prompt: "Finding helpful articles...",
                options: []
            }]);
            const formData = new FormData();
            formData.append('phrase', 'new connection');
            try {
                const response = await axios.post(window.base_url + "documents/search", formData).then(function (response) {
                    if (response.data.results !== undefined) {

                        const article_list = [];
                        response.data.results.forEach(element => {
                            article_list.push({ id: element.post_id, text: element.post_text, title: element.post_title });
                        });

                        setArticles(article_list);

                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<h6>Here are some helpful articles:</h6><ul>",
                            list: article_list
                        }]);
                    }
                });
            }
            catch (error) { }
        },

        WebChat: () => { 

            if(chatOffline())
            {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-warning'>" + config.value.chat_offline + "</div>",
                    options: [{ process: "LeaveMessage", text: "Leave a message", icon: "", color: "btn-info"}]
                }]);
            }

            else if(slowResponse())
            {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-warning'>" + config.value.slow_response + "</div>",
                    options: []
                }]);

                setTimeout(function(){
                    startChat(); 
                }, 6000);                 
            }

            else 
            {
                startChat();
            }                     
        },

        Whatsapp: () => {
            window.open('https://api.whatsapp.com/send/?phone=27832691456&text&type=phone_number&app_absent=0', '_blank');
        },

        LeaveMessage: () => { 
            setForm(true);
            let icon = document.getElementById('siluluQuickChat');
            icon.style.display = 'block';
            setChat(true);
        },

        SupplyInterruption: async () => {
            setInput(false);
            const formData = new FormData();
            try {
                const response = await axios.get(window.base_url + "notices/active").then(function (response) {
                    if (response.data !== undefined && response.data.results !== undefined) {
                        if (response.data.results.length > 0) {
                            try {
                                axios.get(window.base_url + "documents/api").then(function (response) {
                                    if (response.data.notices !== undefined) {
                                        setNotices(response.data.notices);
                                    }
                                });
                            }
                            catch (error) {
                            }
                            const towns = [];
                            const town_list = [];
                            response.data.results.forEach(notice => {
                                notice.areas.forEach(area => {
                                    if (towns.indexOf(area.area_display) < 0) {
                                        towns.push(area.area_display);
                                        town_list.push({ process: "AreaNotices", text: area.area_display, icon: "", color: "btn-info"});
                                    }
                                });
                            });

                            setMessage([...messages, {
                                entity: "bot",
                                prompt: `There are active outages in the areas below. Please select one for details:`,
                                options: town_list
                            }]);

                        }
                        else {
                            setMessage([...messages, {
                                entity: "bot",
                                prompt: "<div class='alert alert-info'>There are currently no active reported issues.</div>",
                                options: []
                            }]);
                        }
                    }
                    else {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-danger'>We are experiencing a technical issue. Please try again later.</div>",
                            options: []
                        }]);
                    }
                });
            }
            catch (error) { }
        },

        AreaNotices: (area) => {
            setScroll(false);
            let published = "";
            notices.forEach(notice => {
                if (notice.area_display === area) {
                    const locations = {};
                    notice.areas.forEach(element => {
                        if (locations[element.area_display] !== undefined) {
                            locations[element.area_display].push(element.sub_area_name);
                        }
                        else {
                            locations[element.area_display] = [];
                            locations[element.area_display].push(element.sub_area_name);
                        }
                    });
                    published += `<article id="post" class="documentation_info"><div class="notice">`; 
                    published += notice.category_id < 7 ? `<h1 class="title_notice">NOTICE<h1>`:
                    `<h1 class="title_header">${notice.notice_title}<h1>`;
                    
                    published += `</div>`;

                    published += notice.category_id === 14 ? `<div class='notice_shutdown'>${notice.notice_shutdown}</div>` : `<div class='notice_header'>${notice.category_name}</div>`;

                    if(notice.category_id != 14) {
                        published += `<div class="notice_bg">
                        <h6>${notice.notice_title}`;  
                        
                            if(locations != {})
                            {
                                for(let location in locations)
                                { 
                                    published += ` in `; 
                                    locations[location].forEach((place, i) => {  
                                        published += place + ', &nbsp;'; 
                                    }); 

                                    published += `(${location})`;
                                }
                            }
                            
                            published += `</h6>
                            <div class="notice_mid">

                            ${notice.notice_text }`;

                            if(notice.notice_image != "" 
                            && (notice.notice_image.includes("jpg") || notice.notice_image.includes("jpeg") || notice.notice_image.includes("png"))) {
                                published += `<figure>
                                <img src=${notice.notice_image} alt='' class='img-fluid'/> 
                                </figure>`;
                            }
                            
                            if(notice.notice_area_streets != "") {
                            published += `
                            <span class="notice_bottom">Areas/streets affected:</span>
                            <div class="notice_streets">${notice.notice_area_streets != null ? notice.notice_area_streets.replaceAll(",",", ") : ""}  and surrounding streets</div>
                            <br> `;
                            }
            
                            published += `</div> 
                            <p>We apologise for any inconvenience and thank you for your patience.</p>
                            </div>`; 
                        }

                        else
                        {
                            published += `<div class="notice_bg_planned">
                
                            ${notice.notice_text }`; 

                            published += `<p>It is advised for all consumers to use water sparingly should the maintennance carry on for longer periods than planned.</p>

                            <p>We will endeavour to minimize the period required and will liase with you, to keep you informed of the progress of works.</p>
                
                            </div>`; 
                        }

                    published += ` </article>`; 
                }
            });

            setMessage([...messages, {
                entity: "bot",
                prompt: published,
                options: []
            }]);

            setScroll(true);
        },

        SelectEmail: async (id) => {
            setMessage([...messages, {
                entity: "bot",
                prompt: "Sending Request...",
                options: []
            }]);

            const formData = new FormData();
            formData.append('email', id);
            formData.append('account', profile.CUSTKEY);
            try {
                const response = await axios.post(window.base_url + "chats/statement/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-success'>Statement for account number: " + profile.CUSTKEY + " will be sent shortly to: " + id + "</div>",
                            options: []
                        }]);
                    }
                    else {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                            options: []
                        }]);
                    }
                });
            }
            catch (error) {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                    options: []
                }]);
            }
        },

        SelectAccount: async (id) => {
            const formData = new FormData();
            formData.append('account_number', id);
            formData.append('getBalance', !0);
            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {

                        if (response.data.last_statement !== undefined) {
                            setLoggedIn(true);
                            setMessage([...messages,
                            {
                                entity: "bot",
                                prompt: ``,
                                options: [
                                    { process: "AccountBalance", text: "View Account Balance", icon: faWallet, color: "btn-warning"}, 
                                    { process: "PaymentLink", text: "Pay Online Securely", icon: faCreditCard, color: "btn-warning"}, 
                                    { process: "AccountTransactions", text: "View Account Transactions", icon: faListNumeric, color: "btn-warning"},
                                    { process: "AccountStatement", text: "Request Statement", icon: faEnvelope, color: "btn-info"}, 
                                    { process: "UpdateDetails", text: "Update Account Details", icon: faFileClipboard, color: "btn-info"}, 
                                    { process: "AccountStatus", text: "Check Account Status", icon: faCircleCheck, color: "btn-info"},                                 
                                    { process: "ReportIssue", text: "Report New Issue", icon: faFileCirclePlus, color: "btn-success"},                                      
                                    { process: "ChatOptions", text: "Chat with a person", icon: faComments, color: "btn-success"} ]
                            }]);

                            let cust_key = customer + "_" + id;
                            setCustomer(cust_key);

                            let date = response.data.last_statement.BILNG_DATE;
                            let billing_date = date.split(" ");                           
                            
                            const formData = new FormData();
                            formData.append('account_number', id);
                            formData.append('getGraphData', !0);
                            formData.append('billing_date', billing_date[0]);
                            
                            try {
                                const response = axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                                    if (response.data !== undefined) {
                                        
                                        let months = [];
                                        let data = [];                            
                        
                                        response.data.forEach(function(item){ 
                                            months.push(item.bilng_date.substring(0,7));
                                            data.push(parseInt(item.consump));
                                        }); 

                                        setChartData(data);
                                        setChartLabels(months)
                                    }
                                });
                            }
                            catch (error) { }
                        }
                    }
                    else {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-warning'>Account number <strong>" + id + "</strong> is inactive. Please select a different acccount.</div>",
                            options: []
                        }]);
                    }
                });
            }
            catch (error) { }
        }, 
        AccountTransactions: async () => {
            setInput(false);
            const formData = new FormData();
            formData.append('account_number', profile.CUSTKEY);
            formData.append('getTransactions', !0);

            let type = "";
            let amount = 0; 
            let balance = 0; 
            let vat = 0;
            let bal = 0;
            let count = 0;
            let statement = 0;
            let data = [];

            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {
                        let current_balance = GetBalance(response.data.transactions);
                        let transactions = response.data.transactions.reverse();
                        
                        transactions.forEach((item, index) => {
                            count++;
                            let i = index - 1;
                            if(item.trns_type < 50) {
                                type = 'Credit';
                            }
                            else {
                                type = 'Debit';
                            }
        
                            if(item.vat_amount === "NULL" || item.vat_amount === undefined || item.vat_amount === '') {
                                
                                vat = ''; 
                            }
                            else {
                                vat = 'R' + parseFloat(item.vat_amount).toFixed(2); 
                            }  

                            if(index === 0)
                            {
                                bal = current_balance;
                            }
                            else
                            {
                                bal = parseFloat(bal) + parseFloat(transactions[i].amount);
                            }                        
            
                            if(bal.toString().length < 3) {
                                balance = 'R' + bal.toFixed(2);
                            }
                            else {                     
                            
                                balance = 'R' + parseFloat(bal).toFixed(2);                                          
                            }
                               
                            var effect_date = item.effect_dte.substring(0, 10);               
                            amount = 'R' + parseFloat(item.amount).toFixed(2);
 
                            data.push({
                                id: count,
                                Date: effect_date,
                                OpeningBalance: statement,
                                Type: type,
                                Description: item.trns_stitl,
                                Amount: amount, 
                                Balance: balance
                            });
                             
                        setValues(data);
                        setTransModal(true);
                    });
                    }
                    else {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                            options: []
                        }]);
                    }
                }); 
            }
            catch (error) { }
        },
 

        AccountBalance: async () => {
            setInput(false);
            const formData = new FormData();
            formData.append('account_number', profile.CUSTKEY);
            formData.append('getTransactions', !0);
            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {

                        let current_balance = GetBalance(response.data.transactions);

                        const current = {
                            entity: "bot",
                            prompt: `<div class='alert alert-success'>Your account balance is: <strong>R${current_balance}</strong></div>`,
                            options: []
                        }

                        setBalance(current_balance);
                        setMessage([...messages, current]);
                    }
                    else {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                            options: []
                        }]);
                    }
                });
            }
            catch (error) { }
        },

        Dev: () => {   
            setAccounts([{CUSTKEY: value.substring(1)}]);  
            Step.Authenticate(phone);                      
        },

        Authenticate: async (phone) => {

            const formData = new FormData();
            formData.append('authenticate', !0);
            formData.append('account_number', phone);
            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data.status !== undefined) {
                        if (response.data.status === 1) {
                            setAction("");
                            setAuth(false);
                            if(!dev)
                            {
                                setAccounts(response.data.accounts);
                            }                                                    

                            Step["SendOtp"]();
                        }
                        else {
                            setMessage([...messages, {
                                entity: "bot",
                                prompt: "<div class='alert alert-warning'>" + value + " was not found in our system. Please check the number and try again.</div>",
                                options: []
                            }]);
                        }
                    }
                    else {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                            options: []
                        }]);
                    }
                });
            }
            catch (error) {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                    options: []
                }]);
            }
        },

        PaymentLink: async () => {
            const formData = new FormData();
            formData.append('account_number', profile.CUSTKEY);
            formData.append('getTransactions', !0);

            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {                         

                        let current_balance = GetBalance(response.data.transactions);

                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "A payment link will be generated for the amount you submit below.",
                            options: []
                        }]);

                        setValue(current_balance);
                        setAmount(true);
                        setInput(true);
                    }
                    else {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                            options: []
                        }]);
                    }
                });
            }
            catch (error) { }
        },

        SetAmount: async () => {
            setMessage([...messages,
            {
                entity: "bot",
                prompt: `Creating a payment link...`,
                options: []
            }]);

            let last_digit = 0;    
            let account = profile.CUSTKEY;
    
            let num = [4,3,2,7,6,5,4,3];
            let count = 0;
    
            for (let i = 0; i < account.length; i++) {
                    let val = account.charAt(i) * num[i];
                    count += val;
                }
    
            let mod = count % 11;
    
            switch(mod) {
                case 0 : last_digit = 0;
                break;
    
                case 1 : last_digit = 0;
                break;
    
                default : last_digit = 11 - mod;
            }

            const formData = new FormData();
            formData.append('CountryCode', 'ZA');
            formData.append('Amount', parseFloat(value.replace(/,/g, "")));
            formData.append('TransactionReference', profile.CUSTKEY + last_digit);
            formData.append('BankReference', profile.CUSTKEY + last_digit);
            formData.append('CancelUrl', window.base_url + "login/cancelled");
            formData.append('CurrencyCode', 'ZAR');
            formData.append('ErrorUrl', window.base_url + "login/error");
            formData.append('NotifyUrl', window.base_url);
            formData.append('SiteCode', 'SEM-SEM-001');
            formData.append('SuccessUrl', window.base_url + "login/success");

            const options = {
                method: 'POST',
                body: formData
            };

            fetch(window.base_url + "login/payment_link", options)
                .then(response => response.text())
                .then(data => {

                    setMessage([...messages,
                    {
                        entity: "bot",
                        prompt: `Done.`,
                        options: []
                    }]);

                    data = JSON.parse(data);
                    if (data.url != null) {
                        setMessage([...messages,
                        {
                            entity: "bot",
                            prompt: `<hr><h4>Your payment link is ready</h4><a href="${data.url}" class="btn btn-success" target="_blank">Go to Payment Page</a>`,
                            options: []
                        }])
                    }
                    else {
                        setMessage([...messages,
                        {
                            entity: "bot",
                            prompt: data.errorMessage,
                            options: []
                        }])
                    }
                })
                .catch(error => console.error(error));
        },
        SendOtp: async () => {
            const formData = new FormData();
            formData.append('number', phone);
            formData.append('send_opt', !0);
            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {
                        Step["OTPSent"]("");
                        if(response.data.otp !== undefined)
                        {
                            setValue(response.data.otp);
                        }                        
                    }
                });
            }
            catch (error) {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                    options: []
                }]);
            }
        },

        ShowAccounts: (data) => {
            if (accounts.length > 0) {
                const AccountList = [];

                accounts.forEach(account => {
                    AccountList.push({ process: "SelectAccount", text: account.CUSTKEY, icon: "", color: "btn-info"});
                });

                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-success'>There are " + accounts.length + " account(s) linked to the phone number. Please select one.</div>",
                    options: AccountList
                }]);
            }
        },

        GetAccount: async () => {
            const formData = new FormData();
            formData.append('account_number', accounts[0].CUSTKEY);
            formData.append('authenticated', !0);
            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {
                        let account_info = response.data.account;
                        setProfile(account_info);
                        setAccountData(account_info);
                        let name = "";
                        if (account_info.TITLE != null) {
                            name += account_info.TITLE + " ";
                        }

                        if (account_info.INITIAL != null) {
                            name += account_info.INITIAL + " ";
                        }

                        if (account_info.SURNAME != null) {
                            name += account_info.SURNAME;
                        }

                        setCustomer(name);
                        setUser(name);
                        Step.ShowAccounts(account_info);
                    }
                })
            }
            catch (error) {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                    options: []
                }]);
            }
        },

        CheckOtp: async () => {
            const formData = new FormData();
            formData.append('check_otp', value);
            formData.append('send_opt', !0);
            formData.append('phone', phone);
            try {
                const response = await axios.post(window.base_url + "login/bridge/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {
                        setAction("");
                        setOtp(false);
                        setValue("");
                        Step.GetAccount();
                    }
                    else {
                        setAction("");
                        setInput(true);
                        Step["IncorrectOtp"]();
                    }
                });
            }
            catch (error) {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later</div>",
                    options: []
                }]);
            }
        } 
    }

   

    const List = (props) => {
        return (
            <li onClick={() => ViewArticle(props.id)}><a>{props.title}</a></li>
        )
    }

    const Option = (props) => {
        return (
            <div className={props.icon != "" ? "button" : "regular"}>
                <button className={"btn " + props.color} onClick={() => { Step[props.process](props.id); }}>
                    {props.icon != "" ? <FontAwesomeIcon icon={props.icon} /> : props.text}
                </button>
                {props.icon === "" ? "" : props.text}
            </div>
        )
    }

    const ViewArticle = async (id) => {
        try {
            const response = await axios.get(window.base_url + "documents/post_api/" + id).then(function (response) {
                if (response.data !== undefined && response.data.results !== undefined) {
                    setArticle(response.data.results);
                    const view = articles.filter(item => item["id"] === id);
                    setArticle(view[0]);
                    setArticleModal(true);
                }
            });
        }
        catch (error) { }
    }

    const ValidEmail = (input) => {

        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (input.value.match(validRegex)) {
            return true;
        }
        return false;
    }

    const GetBalance = (data) => {
        setInput(false);
        let current_balance = 0;
        let bal = 0; 

            data.forEach((item, index) => { 

            if (index === 0) {
                bal = parseFloat(item.OP_BLNCE) + parseFloat(item.amount);
            }
            else {
                bal = parseFloat(bal) + parseFloat(item.amount);
            }

            if (bal.toString().length < 3) {
                current_balance = bal.toFixed(2);
            }

            else {
                current_balance = parseFloat(bal).toFixed(2);
            }
        });

        if (current_balance > 0) {
            current_balance = current_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        else {
            current_balance = current_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return current_balance;
    }

  
      const onFileClick = () => { 
        inputFile.current.click();
      };
 

      const handleFile = async (event) => {
        if(event.target.files[0].name.includes("png") || 
        event.target.files[0].name.includes("jpeg") || 
        event.target.files[0].name.includes("gif") || 
        event.target.files[0].name.includes("jpg")
        ){
         let fileInput = false;
         if (event.target.files[0]) {
           fileInput = true;
         }
         if (fileInput) {
           try {
             Resizer.imageFileResizer(
               event.target.files[0],
               600,
               600,
               "JPEG",
               100,
               0,
               (uri) => { 
                 setInputValue(uri); 
                 setFile(uri); 
                 setPreview(true);  
               },
               "base64",
               500,
               500
             );
           } catch (err) { 
             
           }
         }
        }    
         else 
         {
           alert("Allowed File Types are: JPEG, PNG, GIF, JPG");
         }
       }
   

    const handleAccountSubmit = async (e) => {
        e.preventDefault();
        setUpdateModal(false); 
        const form = new FormData(e.currentTarget); 
        const formData = new FormData();

        formData.append("current_email", profile.E_MAIL);
        formData.append("current_phone", profile.CELL_TEL_NO);
        formData.append("account", profile.CUSTKEY);
     
        formData.append("cell", form.get("cell"));
        formData.append("email", form.get("email"));
        formData.append("phone", form.get("work_tel"));
        formData.append("address1", form.get("UA_ADRESS1"));
        formData.append("address2", form.get("UA_ADRESS2"));
        formData.append("address3", form.get("UA_ADRESS3"));
        formData.append("address4", "UA_ADRESS4"); 

        try {
            const response = await axios.post(window.base_url + "chats/account_details/", formData).then(function (response) {
                if (response.data !== undefined && response.data.status === 1) {
                    setMessage([...messages, {
                        entity: "bot",
                        prompt: "<div class='alert alert-success'>Your details have been received and will get updated shortly.</div>",
                        options: []
                    }]);                    
                    
                    setFile(""); 
                    setPreview(false);
                    
                }
                else {
                    setMessage([...messages, {
                        entity: "bot",
                        prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                        options: []
                    }]);
                }
            });
        }
        catch (error) {
            setMessage([...messages, {
                entity: "bot",
                prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                options: []
            }]);
        }
    }

    const handleSupplierRegisterSubmit = async (e) => { 
        e.preventDefault();
        setRegisterFeedback(<ProgressBar
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass=""
            /> );

        if(!registerOTP){
            const formData = new FormData();   
            const form = new FormData(e.currentTarget); 
    
            const formDataObj = {};
            form.forEach((value, key) => (formDataObj[key] = value)); 
            var encrypted = CryptoJS.AES.encrypt(JSON.stringify(formDataObj), sess, {format: CryptoJSAesJson}).toString();
            formData.append("payload", encrypted);     
            try {
                const response = await axios.post(window.base_url + "supplier/register/", formData).then(function (response) { 
                        if (response.data !== undefined &&
                        response.data.status === 1) { 
                            setRegisterFeedback("A one time pin has been sent to your email address"); 
                            setRegisterOTP(true);
                        }
                        else 
                        {
                            setRegisterFeedback(response.data.message);
                        }
                });
                     
            }
            catch (error) {
                setRegisterFeedback("There seems to be a technical glitch. Please try again later.");                    
            }   
        }  
        
        else
        {
            const formData = new FormData();   
            const form = new FormData(e.currentTarget);    
            const formDataObj = {};
            form.forEach((value, key) => (formDataObj[key] = value));

            if(formDataObj.pin == ""){
                setRegisterFeedback("You cannot submit an empty OTP!"); 
            }
            else{
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify(formDataObj), sess, {format: CryptoJSAesJson}).toString();
                formData.append("payload", encrypted);     
                try {
                    const response = await axios.post(window.base_url + "supplier/register/", formData).then(function (response) {  
                        if (response.data !== undefined &&
                        response.data.status === 1) {  
                            setRegisterFeedback("OTP Verified. Logging you in..."); 
                            setTimeout(() => {
                                window.location.replace(window.base_url + "supplier/authenticated/"+response.data.token); 
                            }, 2000);   
                        }
                        else 
                        {
                            setRegisterFeedback(response.data.message);
                        }
                    });                     
                }
                catch (error) {
                    setRegisterFeedback("There seems to be a technical glitch. Please try again later.");                    
                }  
            }
       
        }  
    }

    const handleSupplierResetSubmit = async (e) => { 
        e.preventDefault();
        setResetFeedback(<ProgressBar
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass=""
            /> );

        if(!resetOTP){
            const formData = new FormData();   
            const form = new FormData(e.currentTarget); 
    
            const formDataObj = {};
            form.forEach((value, key) => (formDataObj[key] = value)); 
            var encrypted = CryptoJS.AES.encrypt(JSON.stringify(formDataObj), sess, {format: CryptoJSAesJson}).toString();
            formData.append("payload", encrypted);     
            try {
                const response = await axios.post(window.base_url + "supplier/reset/", formData).then(function (response) { 
                        if (response.data !== undefined &&
                        response.data.status === 1) { 
                            setResetFeedback("A one time pin has been sent to your email address"); 
                            setResetOTP(true);
                        }
                        else 
                        {
                            setResetFeedback(response.data.message);
                        }
                });
                     
            }
            catch (error) {
                setRegisterFeedback("There seems to be a technical glitch. Please try again later.");                    
            }   
        }  
        
        else
        {
            const formData = new FormData();   
            const form = new FormData(e.currentTarget);    
            const formDataObj = {};
            form.forEach((value, key) => (formDataObj[key] = value));

            if(formDataObj.pin == ""){
                setResetFeedback("You cannot submit an empty OTP!"); 
            }
            else{
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify(formDataObj), sess, {format: CryptoJSAesJson}).toString();
                formData.append("payload", encrypted);     
                try {
                    const response = await axios.post(window.base_url + "supplier/reset/", formData).then(function (response) {  
                        if (response.data !== undefined &&
                        response.data.status === 1) {  
                            setResetFeedback("OTP Verified. Logging you in..."); 
                            setTimeout(() => {
                                window.location.replace(window.base_url + "supplier/authenticated/"+response.data.token); 
                                }, 2000);   
                        }
                        else 
                        {
                            setResetFeedback(response.data.message);
                        }
                    });                     
                }
                catch (error) {
                    setResetFeedback("There seems to be a technical glitch. Please try again later.");                    
                }   
            }
        }  
    }

    
    const handleLoginSubmit = async (e) => { 
        e.preventDefault();
        setLoginFeedback(<ProgressBar
        visible={true}
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
        /> );

    
        const formData = new FormData();   
        const form = new FormData(e.currentTarget);    
        const formDataObj = {};
        form.forEach((value, key) => (formDataObj[key] = value)); 
        var encrypted = CryptoJS.AES.encrypt(JSON.stringify(formDataObj), sess, {format: CryptoJSAesJson}).toString();
        formData.append("payload", encrypted);     
        try {
            const response = await axios.post(window.base_url + "supplier/id/", formData).then(function (response) { 
                if (response.data !== undefined &&
                    response.data.status === 1) {  
                        setLoginFeedback("Sending OPT...");                         
                        window.location.replace(window.base_url + "supplier/authenticate/"+response.data.token);  
                    }
                    else 
                    {
                        setLoginFeedback(response.data.message);
                        setTimeout(() => {
                            setLoginFeedback("");
                        }, 2000);   
                    }
            });
                    
        }
        catch (error) {
            setLoginFeedback("There seems to be a technical glitch. Please try again later.");                    
        }   
        
    }


    const handleReportSubmit = async (e) => {
        e.preventDefault();
        setReportModal(false);
        const form = new FormData(e.currentTarget);
        if(form.get("issue_details") === "")
        {
            setMessage([...messages, {
                entity: "bot",
                prompt: "<div class='alert alert-danger'>Your report could not be submitted without details. Please try again.</div>",
                options: []
            }]);
        }

        else if(form.get("contact_number") != "" || form.get("email") != "")
        {
            const formData = new FormData();
            formData.append("name", form.get("name"));
            formData.append("phone", form.get("contact_number"));
            formData.append("email", form.get("email"));
            formData.append("location", form.get("issue_location"));
            formData.append("details", form.get("issue_details"));
            formData.append("photo", inputValue);
    
            try {
                const response = await axios.post(window.base_url + "chats/new_issue/", formData).then(function (response) {
                    if (response.data !== undefined && response.data.status === 1) {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-success'>Your report was submitted successfully!</div>",
                            options: []
                        }]);                    
                         
                        setFile(""); 
                        setPreview(false);
                        
                    }
                    else {
                        setMessage([...messages, {
                            entity: "bot",
                            prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                            options: []
                        }]);
                    }
                });
            }
            catch (error) {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "<div class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</div>",
                    options: []
                }]);
            }           
        } 
        else
        {
            setMessage([...messages, {
                entity: "bot",
                prompt: "<div class='alert alert-warning'>Please provide some contact information</div>",
                options: []
            }]);
        }      
    }

    const startChat = () => {
        if (customer != "") {
            window.sessionStorage.setItem("name", customer);
            let icon = document.getElementById('siluluQuickChat');
            icon.style.display = 'block';
            setChat(!chat);
        }

        let _user = window.sessionStorage.getItem("name");
        if (customer === "" && _user === undefined) {
            setAction(""); setInput(true); setName(true); setMessage([...messages,
            {
                entity: "bot",
                prompt: "Please enter your name below:",
                options: []
            }]);
        }
        else {
            let icon = document.getElementById('siluluQuickChat');
            icon.style.display = 'block';
            setChat(!chat);
        }
    }
 
    const slowResponse = () => {
        return currentTime(config.value.slow_response_from, config.value.slow_response_to);
    }

    const chatOffline = () => {
        if(currentTime(config.value.chat_offline_from, "23:59") || tooEarly(config.value.chat_offline_to))
        {
           return true;
        }

        return false; 
    }

   const CryptoJSAesJson = {
        stringify: function (cipherParams) {
            var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
        },
        parse: function (jsonStr) {
            var j = JSON.parse(jsonStr);
            var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
            return cipherParams;
        }
    }

    const currentTime = (from, to) => { 
    let s = from.split(":");
    let e = to.split(":"); 
    let start = parseInt(s[0]) * 60 + parseInt(s[1]);
    let end   = parseInt(e[0]) * 60 + parseInt(e[1]); 
    let now = new Date();
    let time = now.getHours() * 60 + now.getMinutes();
        return time >= start && time < end;
    }

    const tooEarly = (from) => { 
        let s = from.split(":"); 
        let start = parseInt(s[0]) * 60 + parseInt(s[1]); 
        let now = new Date();
        let time = now.getHours() * 60 + now.getMinutes();
        return time < start;
    }

    return (
        <div className='messages' id='messages'>

            <Modal size="" show={articleModal} onHide={() => setArticleModal(false)} >
                <Modal.Header closeButton> <Modal.Title> {article.title} </Modal.Title>
                </Modal.Header>
                <Modal.Body> <div dangerouslySetInnerHTML={{ __html: article.text }} /> </Modal.Body>
            </Modal>

            <Modal size="xl" show={transModal} onHide={() => setTransModal(false)} >
                <Modal.Header closeButton> <Modal.Title>Account Transactions </Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <DataTable
                    columns={columns}
                    data={values} 
                    highlightOnHover 
                    pagination 
                    paginationPosition="top" 
                />
                </Modal.Body>
            </Modal>

            {
                messages.map(function (data, key) {
                    return (
                        <div key={key} id={data.entity} className='response'>
                            <div dangerouslySetInnerHTML={{ __html: data.prompt }} />
                            {
                            data['list'] !== undefined ?
                                <ul>
                                    {data['list'].map(function (li, i) {
                                        return (
                                            <List key={i} title={li.title} id={li.id} />
                                        )
                                    })}
                                </ul> :
                                data['options'].map(function (option, i) {
                                    return (
                                        <Option key={i} id={option.text} process={option.process} text={option.text} icon={option.icon} color={option.color} />
                                    )
                                })
                            }
                        </div>
                    )
                })
            }

            <StepsContext.Provider value={{ action, setAction, messages, setMessage, amount, supplier }}>
                {input === true ? <Input /> : ''}
            </StepsContext.Provider>

            <Modal size="" show={resetSupplierModal} onHide={() => { 
                setResetSupplierModal(false);  
                }} >
                <Modal.Header closeButton> <Modal.Title> Supplier Password Reset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSupplierResetSubmit}>
                        {resetFeedback != "" ? 
                        <p className="alert alert-info mb-3">{resetFeedback}</p> : <></> }
                      
                        <div style={resetOTP === true ? {display: 'none'} : {}}>

                            <div className="form-group mb-3">
                                <label className="label">Email Address</label>
                                <input name="email" type="email" className="form-control" required/>
                            </div>    
                        </div>   
                                              
                        <div style={resetOTP === false ? {display: 'none'} : {}}>
                            <div className="form-group mb-3">
                                <label className="label">Enter OTP Below</label>
                                <input name="pin" type="text" className="form-control" />
                            </div>
                        </div>
                        
                        <div className="btn-group mb-3"> 
                                <button type="submit" className="btn btn-success">Submit</button>
                        </div> 
                    </form>
                </Modal.Body>
            </Modal>  


            <Modal size="" show={registerSupplierModal} onHide={() => { 
                setRegisterSupplierModal(false);  
                }} >
                <Modal.Header closeButton> <Modal.Title> Supplier Portal Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSupplierRegisterSubmit}>
                        {registerFeedback != "" ? 
                        <p className="alert alert-info mb-3">{registerFeedback}</p> : <></> }
                      
                        <div style={registerOTP === true ? {display: 'none'} : {}}>
                            <div className="form-group mb-3">
                                <label className="label">Vendor Number</label>
                                <input name="vendor" type="text" className="form-control" required/>
                            </div>    

                            <div className="form-group mb-3">
                                <label className="label">Email Address</label>
                                <input name="email" type="email" className="form-control" required/>
                            </div>    
                        </div>   
                                              
                        <div style={registerOTP === false ? {display: 'none'} : {}}>
                            <div className="form-group mb-3">
                                <label className="label">Enter OTP Below</label>
                                <input name="pin" type="text" className="form-control" />
                            </div>
                        </div>
                        
                        <div className="btn-group mb-3"> 
                                <button type="submit" className="btn btn-success">Submit</button> 
                        </div> 
                    </form>
                </Modal.Body>
            </Modal>  

            <Modal size="" show={reportModal} onHide={() => { setReportModal(false); setFile(""); setPreview(false); }} >
                <Modal.Header closeButton> <Modal.Title> Reporting New Issue </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleReportSubmit}>
                    <div className="form-group mb-3">
                            <label>Name</label>
                                <input name="name" type="text" defaultValue={profile.INITIAL !== undefined ? profile.INITIAL +" "+profile.SURNAME : ""} className="form-control" />
                            </div> 
                            <div className="form-group mb-3">
                                <label>Email Address</label>
                                <input name="email" type="text" defaultValue={profile.E_MAIL !== undefined ? profile.E_MAIL.includes(",") ? profile.E_MAIL.split(",")[0] : profile.E_MAIL : ""} className="form-control" />
                            </div>
                            <div className="form-group mb-3">
                                <label>Contact Number</label>
                                <input name="contact_number" type="text" defaultValue={profile.WORK_TELNO !== undefined ? profile.WORK_TELNO : ""} className="form-control" />
                            </div>
                            <div className="form-group mb-3">
                                <label>Issue Location</label>
                                <input name="issue_location" type="text" className="form-control" />
                            </div> 
                            <div className="form-group mb-3">
                                <label>Issue Details</label>
                                <textarea name="issue_details" className="form-control"></textarea>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="btn-group mb-3">
                                    <div className='preview'>
                                        <img src={file} />
                                    </div>
                                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleFile}/> 
                                    </div> 
                                </div>
                                <div className="col-md-6">
                                    <div className="btn-group mb-3">
                                        <span className="btn btn-info" onClick={onFileClick}>Attach a photo</span>
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div> 
                                </div>
                            </div>                  
                    </form>
                </Modal.Body>
            </Modal>  

            <Modal size="lg" show={updateModal} onHide={() => setUpdateModal(false)} >
                <Modal.Header closeButton> <Modal.Title> {profile.INITIAL + " " + profile.SURNAME} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleAccountSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group mb-3">
                                <label>Surname</label>
                                <input name="surname" type="text" defaultValue={profile.SURNAME} className="form-control" readOnly />
                            </div>
                            <div className="form-group mb-3">
                                <label>Initials</label>
                                <input name="initials" type="text" defaultValue={profile.INITIAL} className="form-control" readOnly />
                            </div>
                            <div className="form-group mb-3">
                                <label>Email Address</label>
                                <input name="email" type="text" defaultValue={profile.E_MAIL} className="form-control" />
                            </div>
                            <div className="form-group mb-3">
                                <label>Cellphone Number</label>
                                <input name="cell" type="text" defaultValue={profile.CELL_TEL_NO} className="form-control" />
                            </div>

                            <div className="form-group mb-3">
                                <label>Alternative Number</label>
                                <input name="work_tel" type="text" defaultValue={profile.TEL_NO} className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            
                        <div className="form-group mb-3">
                                <label>Stand No. / Street Name & No. / P.O. Box</label>
                                <input name="UA_ADRESS1" type="text" defaultValue={profile.UA_ADRESS1} className="form-control" />
                            </div>
                            <div className="form-group mb-3">
                                <label>Town</label>
                                <input name="UA_ADRESS2" type="text" defaultValue={profile.UA_ADRESS2} className="form-control" />
                            </div>
                            <div className="form-group mb-3">
                                <label>Suburb</label><input name="UA_ADRESS3" type="text" defaultValue={profile.UA_ADRESS3} className="form-control" />
                            </div>
                            <div className="form-group mb-3">
                                <label>Postal Code</label>
                                <input name="UA_ADRESS4" type="text" defaultValue={profile.UA_ADRESS4} className="form-control" />
                            </div>
                            <div className="form-group mb-3">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </div>
                    </div>                              
                    </form>
                </Modal.Body>
            </Modal>    

             {loginModal ?
            <div className="row"><div className="col-md-6">
            <div className="card"><div className="card-body">
            <form onSubmit={handleLoginSubmit}>                
                
                <div className="form-group mb-3">
                    <label className="label text-white">Email Address</label>
                    <input name="email" type="email" className="form-control" required/>
                </div> 

                <div className="form-group mb-3">
                    <label className="label text-white">Password</label>
                    <input name="password" type="password" className="form-control" required/>
                </div> 

                { loginFeedback != "" ? <div className="alert alert-info mb-3">{loginFeedback}</div> : <></> }

                <div className="btn-group mb-3"> 
                        <button type="submit" className="btn btn-success">Submit</button>
                </div> 
            </form></div></div></div></div> : <></> }
        </div>
    )
}


export default Steps;